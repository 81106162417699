import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function Vyaktitva() {
  return (
    <Layout>
      <SEO
        title="Vyaktitva | Chinmaya Vidyalaya"
        description="A message encapsulating the school focus and philosophy, from Ms. Archana Soni, Principal, Chinmaya Vidyalaya Vasant Vihar."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="vyaktitva">
        <h1 className="heading">Vyaktitva</h1>
        {/* <div className="vyaktitva__heading2">Principal's Message</div> */}
        <p className="vyaktitva__text">
          Education is a lifelong process of development of one’s personality
          which starts from the school. In today’s time, when there is a huge
          crisis of moral values in society, value- based education proves to be
          the solution. Through value-based education, we develop our children
          into people with strong character and values who know how to utilize
          their knowledge for the advantage of mankind. With Value Education
          embraced into our curriculum, our students emerge as all-round and
          well-developed individuals, holistically ingrained with a humane
          outlook and integrity of spirit, not just fulfilling their lives but
          also strong enough to meet the challenging times ahead.
        </p>
        <p className="vyaktitva__text">
          The <strong>&#39;Vyaktitva&#39;</strong> of the student is developed
          with the following practices:
          <br />
          <br />
          <strong>*Text Reading</strong> - Scriptures and books based on values.
          <br />
          <strong>*Discussions and interactions</strong> - How can values be
          followed more practically rather than just reading theoretically.{" "}
          <br />
          <strong>*Chanting</strong> - Vedic mantras <br />
          <strong>*Meditation</strong> - Mindfulness and doing
          self-introspection. <br />
          <strong>*Circle time</strong> - Students presenting their ideas and
          life experiences. <br />
          <strong>*Connecting</strong>- the lessons with day-to-day examples{" "}
          <br />
          <strong>*Creating a friendly environment in the class</strong> - Not
          just teaching but learning with the students
          <br />
          <strong>*Sewa Activities</strong> - Serving the family, society,
          school, nation and world.
        </p>
      </div>
    </Layout>
  );
}
